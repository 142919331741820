// Build information, automatically generated by `ng-info`
const build = {
    version: "2.18.0",
    timestamp: "Tue Sep 10 2024 16:48:47 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "7d0311",
        fullHash: "7d03111dcada333e249de51c94d5a3e7156a6587"
    }
};

export default build;